import { ref, computed, onMounted } from '@vue/composition-api'
import useHttp from '@/comp-functions/useHttp'

/**
 * Logic for fetching list into the table
 * Will use in many component, be careful when want to change this function
 * @param {object} payload
 * @param {string} payload.url
 */
export default function useListReportTable (payload) {
  const { $get } = useHttp()

  const isBusy          = ref(true)
  const refListTable    = ref(null)
  const perPage         = ref(15)
  const perPageDefault  = ref(15)
  const itemLists       = ref([])
  const totalList       = ref(0)
  const currentPage     = ref(1)
  const perPageOptions  = [10, 25, 50, 100]
  const searchQuery     = ref('')
  const sortBy          = ref('code')
  const isSortDirDesc   = ref(true)
  const statusFilter    = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: (perPage.value * (currentPage.value - 1)) + (localItemsCount ? 1 : 0),
      to: perPage.value * currentPage.value,
      of: totalList.value
    }
  })

  // fetch data to api endpoint
  const fetchLists  = async (url = payload.url, getTotal, pageNextOrPrevieus) => {
    totalList.value = getTotal
    dataMeta.value.from = pageNextOrPrevieus
    if (url) {
      try {
        const {data} = await $get({
          url
        })
        isBusy.value    = false
        itemLists.value = data
        perPage.value   = data.length < perPageDefault.value ? data.length : perPageDefault.value

      } catch (e) {
        isBusy.value = false
      }
    }
  }

  const changePerPage = (page) => {
    perPage.value = page
    perPageDefault.value = page
  }

  onMounted(fetchLists)

  return {
    isBusy,
    itemLists,
    perPage,
    currentPage,
    totalList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    statusFilter,
    fetchLists,
    changePerPage
  }
}
